import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Movimento.vue";
import PremiosView from "../views/Premios.vue";
import FAQview from "../views/FAQ.vue";
import ContatoView from "../views/Contato.vue";
import Ranking from "../views/Ranking.vue";
import EscolasView from "../views/ListaEscolas.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "movimento",
    component: HomeView,
  },
  {
    path: "/premios",
    name: "premios",
    component: PremiosView,
  },
  {
    path: "/escolas-participantes",
    name: "escolas-participantes",
    component: EscolasView,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQview,
  },
  {
    path: "/contato",
    name: "contato",
    component: ContatoView,
  },
  {
    path: "/ranking",
    name: "ranking",
    component: Ranking,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
