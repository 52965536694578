<template>
  <div class="page movimento">
    <v-row class="hero justify-end">
      <!--
      <v-col cols="12" lg="12" md="12" sm="12" class="hide_desktop">
        <div class="banner_players">
          <v-img src="../assets/player.png" contain class="player_img" />
          <div class="banner_text text-left" style="font-size: 16px; line-height: 22px; color: #ffffff">
            <p class="font-weight-bold" style="margin: 0; padding: 0">
              Se liga, jogador!
            </p>
            <p style="margin: 0; padding: 0">
              A sua inscrição no game começa dia 10/06. <br />
              Cadastre aqui seu e-mail para que a gente te lembre!
            </p>
          </div>

          <v-form class="player_form">
            <v-text-field v-model="playerEmail" label="E-mail" outlined dense required filled background-color="#FFFFFF"
              color="#F66844" style="margin-right: 20px"></v-text-field>
            <v-btn large dark color="#F26F21" @click="playerRegister" class="text-none player_button" :loading="loading"
              :disabled="loading" style="margin-right: 20px">
              Enviar e-mail
            </v-btn>
          </v-form>
        </div>
      </v-col>
        -->

      <!--
        <v-col cols="12" lg="5" md="5" sm="6" class="d-flex flex-column align-start pa-10">
        <h1 class="text-left font-weight-bold hero_title"> Inscrições das escolas e instituições encerradas</h1>
        <p class="text text-left mt-8" style="font-size: 20px; line-height: 25px">
          Mais de 400 cadastros recebidos, e agora é hora de entrar no jogo! <br><br>
          Junte-se ao movimento de inclusão digital que vai revolucionar o futuro da sua escola ou instituição. <br><br>
          Reúna seus jogadores e prepare-se: o start do game é no dia <b>10/06</b>! Não perca essa oportunidade de fazer
          parte dessa transformação!
        </p>
        -->



      <v-col cols="12" lg="5" md="5" sm="6" class="d-flex flex-column align-start pa-10">
        <h1 class="text-left font-weight-bold hero_title"> Que comecem os jogos!</h1>
        <p class="text text-left mt-8" style="font-size: 20px; line-height: 25px">
          Atenção, jogadores! Faça parte do nosso jogo exclusivo que vai desafiar suas habilidades e também gerar uma
          experiência única de aprendizado e diversão. <br><br>
          Esta é a sua chance de mostrar do que é capaz e ainda concorrer ao sorteio de um notebook gamer. <br><br>
          Clique no botão abaixo, escolha sua equipe e explore o mundo da tecnologia jogando!
        </p>


        <div class="mt-6 d-flex flex-column align-start">
          <v-btn large depressed color="#F66844" style="color: #ffffff" class="text-none"
            href="https://desafiodigitalsenac.joco.com.br/" @click="track">Jogar
            agora</v-btn>
        </div>




      </v-col>
      <v-col cols="12" lg="6" md="7" sm="6" class="pa-0 ma-0">
        <v-img src="../assets/hero-schools.png" max-height="600" contain></v-img>
      </v-col>


      <!--
      <v-col cols="12" lg="12" md="12" sm="12" class="hide_tiny_mobile">
        <div class="banner_players">
          <v-img src="../assets/player.png" contain class="player_img" />

          <div class="banner_text text-left" style="font-size: 16px; line-height: 22px; color: #ffffff">
            <p class="font-weight-bold" style="margin: 0; padding: 0">
              Se liga, jogador!
            </p>
            <p style="margin: 0; padding: 0">
              A sua inscrição no game começa dia 10/06. <br />
              Cadastre aqui seu e-mail para que a gente te lembre!
            </p>
          </div>

          <v-form class="player_form">
            <v-text-field v-model="playerEmail" label="E-mail" outlined dense required filled background-color="#FFFFFF"
              color="#F66844" style="margin-right: 20px"></v-text-field>
            <v-btn large dark color="#F26F21" @click="playerRegister" class="text-none" :loading="loading"
              :disabled="loading" style="margin-right: 20px">
              Enviar e-mail
            </v-btn>
          </v-form>
        </div>
      </v-col>
      -->


    </v-row>

    <v-row class="pt-10 pl-10 pr-10">
      <v-col cols="12" lg="1" md="1" class="hide_mobile">
        <v-img src="../assets/triangle.png"></v-img>
      </v-col>
      <v-col cols="12" lg="7" md="7" class="mx-auto">
        <h2 class="text-weight-regular">O Movimento</h2>
        <div class="text-body-1 text-left movimento_text">
          Mais do que uma promoção, a Olimpíada Digital Senac é um movimento
          pela inclusão digital nas escolas e instituições que atendem jovens no
          Rio de Janeiro.
        </div>
        <div class="text-body-1 text-left movimento_text">
          Com o apoio de marcas e empresas de peso, juntamos forças para levar
          conhecimento e recursos à população com menos acesso. Assim, ajudamos
          a furar a bolha tecnológica que separa nossos jovens.
        </div>
        <div class="text-body-1 font-weight-bold text-left movimento_text">
          Confira no vídeo abaixo como foi a edição da Olimpíada Digital Senac
          de 2023
        </div>
      </v-col>
      <v-col cols="12" lg="1" md="1" class="d-flex align-end hide_mobile">
        <v-img src="../assets/plus.png" class="hide_mobile" contain></v-img>
      </v-col>
    </v-row>

    <v-row class="mx-0">
      <v-col>
        <iframe class="hide_mobile" width="940" height="535"
          src="https://www.youtube.com/embed/gAofoCBTZ9c?si=7p_I6VysW6VZVB1E" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <iframe class="hide_desktop" width="340" height="200"
          src="https://www.youtube.com/embed/gAofoCBTZ9c?si=7p_I6VysW6VZVB1E" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-col>
    </v-row>

    <!-- Como funciona -->
    <v-row class="mt-10">
      <v-col cols="12">
        <h2>Como funciona</h2>
      </v-col>
    </v-row>

    <v-row class="pa-4">
      <v-col cols="12" lg="3" md="6" sm="6">
        <v-card height="350" class="pr-6 pl-6 pt-8 justify-center" rounded="8px">
          <v-img src="../assets/start.png" height="90" contain></v-img>
          <v-card-title class="card_title font-weight-bold">Equipes</v-card-title>
          <v-card-text class="pl-0 pr-0" style="color: #000000; font-size: 16px">As escolas e instituições se cadastram
            para se transformarem em
            equipes na Olimpíada Digital, ficando habilitadas a receber
            pontos.</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="6">
        <v-card height="350" class="pr-6 pl-6 pt-8">
          <v-img src="../assets/hands.png" width="200" height="90" contain class="mx-auto"></v-img>
          <v-card-title class="card_title font-weight-bold">Jogadores</v-card-title>
          <v-card-text class="pl-0 pr-0" style="color: #000000; font-size: 16px">A partir do dia 10 de junho, os
            jogadores podem entrar no game e
            escolher para qual equipe desejam doar seus pontos.</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="6">
        <v-card height="350" class="pr-6 pl-6 pt-8">
          <v-img src="../assets/lamp.png" height="90" contain></v-img>
          <v-card-title class="card_title font-weight-bold">Competição</v-card-title>
          <v-card-text class="pl-0 pr-0" style="color: #000000; font-size: 16px">Durante o período de 10/06 à 30/08 os
            competidores devem completar
            os 17 temas da Olimpíada, acumulando pontos.</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="6">
        <v-card height="350" class="pr-6 pl-6 pt-8">
          <v-img src="../assets/prizes.png" height="90" contain></v-img>
          <v-card-title class="card_title font-weight-bold">Sorteio</v-card-title>
          <v-card-text class="pl-0 pr-0" style="color: #000000; font-size: 16px">Ao final da Olimpíada, as escolas com
            mais pontos ganham e os
            competidores participam de um sorteio.</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!--Conteúdo que faz a diferença -->
    <v-row class="mt-10">
      <v-col cols="12">
        <h2>Conteúdo que Faz a Diferença</h2>
      </v-col>
    </v-row>

    <v-slide-group class="badge-slider pa-4" prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right" show-arrows
      center-active>
      <template v-slot:next>
        <v-img src="../assets/right-arrow.png"></v-img>
      </template>

      <template v-slot:prev>
        <v-img src="../assets/left-arrow.png"></v-img>
      </template>

      <v-slide-item class="badge-slide">
        <div class="badge-odd">
          <v-img src="../assets/habilidades.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-even">
          <v-img src="../assets/sustentabilidade.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-odd">
          <v-img src="../assets/ia.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-even">
          <v-img src="../assets/consciencia.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-odd">
          <v-img src="../assets/games.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-even">
          <v-img src="../assets/dados.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-odd">
          <v-img src="../assets/metaverso.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-even">
          <v-img src="../assets/saude.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-odd">
          <v-img src="../assets/iot.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>

      <v-slide-item class="badge-slide">
        <div class="badge-even">
          <v-img src="../assets/5g.png" class="badge-image" contain></v-img>
        </div>
      </v-slide-item>
    </v-slide-group>

    <Footer></Footer>
  </div>
</template>

<script>
import { insertSheetItemOlimpiadaPlayer } from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import Footer from "../components/footer.vue";

export default {
  name: "MovimentoView",
  components: {
    Footer,
  },
  data: () => ({
    playerEmail: "",
    loading: false,
  }),
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    async playerRegister() {
      if (!this.playerEmail)
        return emitToastr(
          "error",
          "Você deve preencher seu e-mail para fazer o pré cadastro."
        );
      if (!this.validEmail(this.playerEmail))
        return emitToastr("error", "Você deve preencher um e-mail válido");

      const data = {
        spreadsheetId: "1gN4Cx5fMFHfBXVvzygywmqarIXoUuuigeMIa2SS-_3c",
        sheetName: "Jogadores",
        values: [[this.playerEmail]],
      };
      this.loading = true;
      const resp = await insertSheetItemOlimpiadaPlayer(data);
      this.loading = false;
      if (resp && resp.data && resp.data.statusText == "OK") {
        emitToastr(
          "success",
          "Pré-cadastro efetuado com sucesso. Aguarde informações em breve."
        );
        this.playerEmail = "";
      }
    },

    track() {
      this.$gtag.event('click_jogar_agora', {
        'event_category': 'click_button',
        'event_label': 'Clicou no botão Jogar agora'
      })
    }
  },
};
</script>

<style scoped>
.movimento {
  background-color: #5b268e;
  min-height: 140vh;
  color: white;
  background-image: radial-gradient(closest-corner circle at 80% 30%,
      #8a1e8b 33.33%,
      rgba(138, 30, 139, 0) 100%),
    radial-gradient(closest-corner circle at 10% 15%,
      #8a1e8b 33.33%,
      rgba(138, 30, 139, 0) 100%),
    radial-gradient(closest-corner circle at 5% 70%,
      #8a1e8b 33.33%,
      rgba(138, 30, 139, 0) 100%);
}

.hero_title {
  font-size: 40px;
  line-height: 45px;
}

.movimento_text {
  font-family: "GothamMedium", sans-serif !important;
  margin: 10px 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 300;
}

.banner_players {
  width: 90%;
  margin: 0 auto;
  height: 120px;
  border-radius: 10px;
  background-color: #6b3c99;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.player_img {
  height: 50px;
  width: 70px;
}

.banner_text {
  width: 50%;
}

.player_form {
  width: 40%;
  display: flex;
  align-items: center;
}

.v-text-field>>>.v-text-field__details {
  display: none !important;
}

.badge-slider {
  height: 600px;
  display: flex;
}

.badge-slide {
  margin: 0 15px;
}

.badge-odd {
  display: flex;
  align-items: start;
}

.badge-even {
  display: flex;
  align-items: end;
}

.badge-image {
  width: 250px;
}

@media screen and (max-width: 900px) {
  .banner_players {
    width: 98%;
  }

  .player_form {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .movimento_text {
    font-size: 16px;
    line-height: 22px;
  }

  .banner_players {
    height: 300px;
    flex-direction: column;
  }

  .banner_text {
    width: 90%;
    text-align: center !important;
  }

  .player_form {
    width: 100%;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .player_button {
    width: 219px;
  }

  .badge-slider {
    height: 350px;
    display: flex;
  }

  .badge-slide {
    width: 120px;
    margin-right: 5px;
  }

  .badge-image {
    width: 170px;
  }
}
</style>
